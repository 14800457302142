<template>
  <div class="inside-bg">
    <b-alert variant="success" role="alert" style="position: fixed;z-index: 2;left: 10px;top: 80px;" :show="showDismissibleAlert" dismissible>Job link copied to clipboard</b-alert>
    <div class="container" style="padding-top: 100px">
      <nav area-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item"><a href="/jobs">Jobs</a></li>
          <li class="breadcrumb-item active" aria-current="page" v-if="job">
            {{ job.job_designation }}
          </li>
        </ol>
      </nav>
      <div class="col">
        <div class="row">
          <div class="col-lg-12 jobDetails">
            <div class="jobHeader" v-if="loading" style="height:200px;">
              <content-loader viewBox="0 0 476 50" primaryColor="#f3f3f3" secondaryColor="#cccccc" v-if="loading">
                <rect x="0" y="8" rx="3" ry="3" width="150" height="10" />
                <rect x="0" y="25" rx="3" ry="3" width="80" height="6" />
                <rect x="90" y="25" rx="3" ry="3" width="80" height="6" />
                <rect x="0" y="40" rx="3" ry="3" width="100" height="6" />
                <rect x="0" y="55" rx="3" ry="3" width="80" height="6" />
              </content-loader>
            </div>
            <div class="jobHeader" v-if="job && !loading">
              <h1 v-if="job.job_designation">{{ job.job_designation }}</h1>
              <h2 v-if="job.company_name">
                <span class="jobIcon">
                  <img :src="$S3_url+'/Assets/images/company.svg'" alt="company name" title="company name" width="18"/>
                </span>
                {{ job.company_name }} &nbsp;&nbsp;
              </h2>
              <div class="salary" v-if="!job.salary_flag &&(job.salary_range_from || job.salary_range_to)">
                <span class="jobIcon">
                  <img :src="$S3_url+'/Assets/images/money.svg'" alt="salary" title="salary" width="30"/>
                </span>
                <p>
                  <span> </span>
                  <span v-if="job.salary_range_from">Rs. {{ job.salary_range_from }}</span>
                  <span v-if="job.salary_range_from && job.salary_range_to"> - </span>
                  <span v-if="job.salary_range_to">Rs. {{ job.salary_range_to }}</span>
                  <span v-if="job.salary_range_from || job.salary_range_to"> per month</span>
                </p>
              </div>
              <div class="locations"  v-if="job.cities">
                <span class="jobIcon">
                  <img :src="$S3_url+'/Assets/images/location.svg'" alt="location" title="location" width="22"/>
                </span>
                <span v-for="(city, index) in job.cities" :key="index">{{ city}}
                  <span v-if="job.cities.length != index + 1">,</span>
                </span>
              </div>
              <button class="btn btn-primary" @click="checkCity()" v-if="!applied&&!expired && job.response_type == 1">Apply now</button>
              <div class="error-feedback" v-if="error">{{err_msg}}</div>
              <div v-if="job.response_type == 2 && !applied && !expired" class="howtoapply">
                <div v-if="job.title_description">
                  <h6><strong>How to apply:</strong></h6>
                </div>
                <div v-html="job.title_description"></div>
                <a class="btn btn-primary btn2" @click="checkCity()" v-if="!applied && job.response_type == 2">{{ job.label1 }}</a>
              </div>
              <button class="btn btn-primary" v-if="applied&&!expired&&q_result>0" disabled>
                Applied
              </button>
              <button class="btn btn-warning" v-if="applied&&!expired&&q_result==0" disabled>
                Partially Applied
              </button>
              <button class="btn btn-primary" v-if="!applied&&expired" disabled>
                Expired
              </button>
              <!-- <p class="copylink"><i class="mdi mdi-link-variant"></i> Copy link to share the job</p> -->
            </div>
            <div class="jobDescription" v-if="loading">
              <div style="height:110px;">
                <content-loader viewBox="0 0 476 124" primaryColor="#f3f3f3" secondaryColor="#cccccc" v-if="loading">
                  <rect x="0" y="5" rx="3" ry="3" width="100" height="6" />
                  <rect x="140" y="5" rx="3" ry="3" width="70" height="6" />
                  <rect x="260" y="5" rx="3" ry="3" width="70" height="6" />
                  <rect x="0" y="20" rx="3" ry="3" width="85" height="6" />
                  <rect x="0" y="35" rx="3" ry="3" width="80" height="6" />
                </content-loader>
              </div>
            </div>
            <div class="jobDescription" v-if="job && !loading">
              <div class="row subDetails">
                <!-- <div class="col-md-4" v-if="jobrole"> Job Role: <span>{{ jobrole.role }}</span></div> -->
                <div class="col-md-4" v-if="c_job_type"> Job Type: <span>{{ c_job_type }}</span></div>
                <div class="col-md-4" v-if="cities.length"> Location:
                  <span v-for="(city, itemObjKey) in cities" :key="city.city_id">{{ city.city_name}}<span v-if="cities.length != itemObjKey + 1">,</span></span>
                </div>
                <div class="col-md-4" v-if="job.experience_from != null || job.experience_to != null">Experience:
                  <span>{{getJobExp(job.experience_from, job.experience_to)}}</span>
                </div>
                <div class="col-md-4" v-if="job.hiring_process">Type: <span> {{ job.hiring_process }}</span></div>
                <div class="col-md-4" v-if="job.last_date">Last Date: <span> {{ job.last_date }}</span></div>
                <div class="col-md-4" v-if="!job.salary_flag && (job.salary_range_from || job.salary_range_to)">Salary per month: 
                  <span v-if="!job.salary_flag && (job.salary_range_from || job.salary_range_to)">
                    <span v-if="job.salary_range_from">Rs. {{ job.salary_range_from }}</span>
                    <span v-if="job.salary_range_from && job.salary_range_to"> - </span>
                    <span v-if="job.salary_range_to">Rs. {{ job.salary_range_to }}</span>
                  </span>
                </div>
                <div class="col-md-4" v-if="job.number_of_vacancy">No. of vacancies: <span>{{job.number_of_vacancy}}</span></div>
                <div class="col-md-4" v-if="job.year_of_passing_from||job.year_of_passing_to">Passout Year: <span v-if="job.year_of_passing_from">{{job.year_of_passing_from}}</span><span v-if="job.year_of_passing_from&&job.year_of_passing_to">-</span><span v-if="job.year_of_passing_to">{{job.year_of_passing_to}}</span></div>
                <div class="col-md-4" v-if="job.gender">Gender: <span>{{getGender(job.gender)}}</span></div>
                <div class="col-md-4" v-if="job.hq_marks_percentage_from">Highest qualification mark : <span>{{job.hq_marks_percentage_from}}%</span></div>
                <!-- <div class="col-md-6" v-if="job.courses">Education:<span v-for="(course , index) in job.courses" :key="index">{{course.name}}<span v-if="job.courses.length != index+1">,</span></span></div> -->
                <!-- <div class="col-md-6" v-if="job.courses">Branch:<span v-for="(course , index) in job.branches" :key="index">{{course.name}}<span v-if="job.courses.length != index+1">,</span></span></div> -->
                <div class="col-md-12" v-if="this.courses.length">Education: <span>{{this.courses.toString()}}</span></div>
                <div class="col-md-12" v-if="this.branches.length">Branch: <span>{{this.branches.toString()}}</span></div>
                <div class="col-md-12" v-if="job.sublocations.length">Sublocation: <span v-for="(location , index) in job.sublocations" :key="index">{{location}}<span v-if="job.sublocations.length != index+1">,</span></span></div>
                <div class="col-md-12" v-if="job.skills.length">Skills: <span v-for="(skill , index) in job.skills" :key="index">{{skill}}<span v-if="job.skills.length != index+1">,</span></span></div>
              </div>
              <div>
                <hr />
                <div class="description mt-12" v-html="job.job_description"></div>
              </div>
              <div class="bottom_apply" v-if="!is_mobile">
                <button class="btn btn-primary" style="color: white;" @click="checkCity()" v-if="!applied&&!expired && job.response_type == 1">Apply now</button>
                <div class="error-feedback" v-if="error">{{err_msg}}</div>
                <div v-if="job.response_type == 2 && !applied && !expired" class="howtoapply">
                  <div v-if="job.title_description">
                    <h6><strong>How to apply:</strong></h6>
                  </div>
                  <div v-html="job.title_description"></div>
                  <a class="btn btn-primary btn2" @click="checkCity()" v-if="!applied && job.response_type == 2" style="color: white;">{{ job.label1 }}</a>
                </div>
                <button class="btn btn-primary" style="color: white;" v-if="applied" disabled>
                  Applied
                </button>
                <button class="btn btn-primary" style="color: white;" v-if="!applied&&expired" disabled>
                  Expired
                </button>
              </div>
            </div>
            <div class="alert alert-primary" role="alert">
              <div class="row">
                <div class="col-md-7"><span>Hireclap doesn't charge fees for any job offers. Beware of job offer scams</span></div>
                <div class="col-md-5 text-right">
                  <span class="text-secondary" style="cursor:pointer;" v-on:click.prevent="copyToClipboard">
                    <v-icon class="share text-primary mdi" size="14px">{{$icons.link}}</v-icon> Copy link to share job
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 relatedJobs" v-if="!job">
              <div class="card" style="border:none !important;margin-bottom:15px;">
                <div class="card-body jobBox p-0">
                  <content-loader viewBox="0 0 476 124" primaryColor="#f3f3f3" secondaryColor="#cccccc" v-if="!job">
                    <rect x="0" y="8" rx="3" ry="3" width="200" height="13" />
                    <rect x="0" y="30" rx="3" ry="3" width="400" height="6" />
                    <rect x="0" y="43" rx="3" ry="3" width="100" height="6" />
                    <rect x="0" y="63" rx="3" ry="3" width="300" height="6" />
                    <rect x="0" y="83" rx="3" ry="3" width="320" height="6" />
                    <rect x="0" y="103" rx="3" ry="3" width="350" height="6" />
                    <rect x="0" y="123" rx="3" ry="3"  width="300" height="6" />
                  </content-loader>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 relatedJobs faq" v-if="job">
            <h5><strong>Read more {{job.company_name}}, {{job.job_designation}}</strong></h5>
            <p v-if="job">{{job.company_name}} is hiring <span v-if="job.job_designation ">{{job.job_designation }}</span> in <span v-if="job.cities.length">{{job.cities.toString()}}</span>.Read the FAQ below for more details about this job opportunity.</p>
            <div class="question expand" v-if="job.salary_flag==0&&job.salary_range_from" v-b-toggle.collapse-a ><p>What salary can I expect for this job?</p></div>
            <b-collapse id="collapse-a" class="answer" v-if="job.salary_flag==0&&job.salary_range_from">
              <p>The offered monthly salary is INR {{job.salary_range_from}} <span v-if="job.salary_range_to">to INR {{job.salary_range_to}}</span> as per the job description. The salary offer also depends on factors like your interview performance, skills, experience, etc. </p>
            </b-collapse>
            <div class="question expand" v-b-toggle.collapse-b ><p>How to apply for this job?</p></div>
            <b-collapse id="collapse-b" class="answer" >
              <p> You can apply for this job simply by clicking on the ‘Apply now’ button on this page. If you are new to our website, you can register by filling in your basic details, verifying your account, and then apply for jobs.</p>
            </b-collapse>
            <div class="question expand" v-if="job.courses.length" v-b-toggle.collapse-c ><p>What is the highest qualification to apply for this job?</p></div>
            <b-collapse id="collapse-c" class="answer" v-if="job.courses.length" >
              <p> Candidates who completed 
                <span v-for="(course , index) in job.courses" :key="index">
                  <span v-if="course.branches.length">
                    <span v-for="(branch,b_index) in course.branches" :key="b_index">{{course.name+'-'+branch}}
                      <span v-if="course.branches.length != index+1">,</span>
                    </span>
                  </span>
                  <span v-else>{{course.name }}</span>
                  <span v-if="job.courses.length != index+1">,</span>
                </span> can apply for this job. Please check other eligibility criteria also before applying for the job.</p>
            </b-collapse>
            <div class="question expand" v-b-toggle.collapse-d ><p>Do I have to complete my profile to apply for this job?</p></div>
            <b-collapse id="collapse-d" class="answer" >
              <p> We strongly recommend completing your profile with all the necessary information. Our AI-driven system uses that information to match your applications for the jobs. Fully completed profiles will have a higher probability of shortlisting for further recruitment.</p>
            </b-collapse>

            <button class="readmore t-color" v-if="readmore == false" @click="readmore=true">↻ Read more FAQ</button>
            <div v-if="readmore == true">
              <div class="question expand" v-b-toggle.collapse-e ><p>How to know that I am shortlisted/selected for the job?</p></div>
              <b-collapse id="collapse-e" class="answer" >
                <p> If you are shortlisted /selected for the job opportunity, the company will directly intimate you via your Hireclap registered email Id/ mobile number.</p>
                <p>Most of the companies intimate the shortlisted /selected candidates only. Sometimes the email may land in your promotions/spam folders also. Suggest checking those folders too. If you are not hearing back from the employer for a long time, high chance that the company does not shortlist you. Continue applying for more matching <a href="/jobs" target="_blank">jobs</a></p>
              </b-collapse>
              <div class="question expand"  v-if="job.cities.length" v-b-toggle.collapse-f ><p>Is this job vacancy available only for the mentioned location(s)?</p></div>
              <b-collapse id="collapse-f" class="answer" v-if="job.cities.length" >
                <p> Yes, this recruitment is for <span v-for="(city, index) in job.cities" :key="index">{{ city}}<span v-if="job.cities.length != index + 1">,</span></span>. To find jobs near your city check out <a href="/jobs-by-cities" target="_blank">Jobs in city</a></p>
              </b-collapse>
              <div class="question expand" v-if="job.job_type" v-b-toggle.collapse-g ><p>What type of job is this?</p></div>
              <b-collapse id="collapse-g" class="answer" v-if="job.job_type" >
                <p> It is a {{c_job_type}} <br> For more details, please read the job description provided by the employer to know about the roles and responsibilities of this position.</p>
              </b-collapse>
              <div class="question expand" v-if="job.skills.length" v-b-toggle.collapse-h ><p>What are the skills required for this job?</p></div>
              <b-collapse id="collapse-h" class="answer" v-if="job.skills.length" >
                <p> The candidate should have the following skills:
                  <ul>
                    <li v-for="(skill,index) in job.skills" :key="index">{{skill}}</li>
                  </ul> 
                </p>
              </b-collapse>
              <div class="question expand" v-if="job.number_of_vacancy" v-b-toggle.collapse-i ><p>What is the number of vacancies for this job?</p></div>
              <b-collapse id="collapse-i" class="answer" v-if="job.number_of_vacancy" >
                <p>The number of vacancies available right now for this job is {{job.number_of_vacancy}}.</p>
              </b-collapse>
              <div class="question expand" v-if="job.hiring_process" v-b-toggle.collapse-j ><p>What is the Hiring Process for this job?</p></div>
              <b-collapse id="collapse-j" class="answer" v-if="job.hiring_process" >
                <p>The hiring for this job is through {{job.hiring_process}}.</p>
              </b-collapse>
              <div class="question expand" v-b-toggle.collapse-k ><p>When can I expect to hear back from the company?</p></div>
              <b-collapse id="collapse-k" class="answer" >
                <p>Typically the hiring companies intimate the selected candidates within a week from the last date of application. This duration varies from company to company, depending on their recruitment process. </p>
              </b-collapse>
              <div class="question expand" v-if="job.last_date" v-b-toggle.collapse-l ><p>What’s the last date to apply?</p></div>
              <b-collapse id="collapse-l" class="answer" v-if="job.last_date" >
                <p>The last date to apply for this job is {{job.last_date}}. Recommend to apply for the job before this date to avoid a last-minute rush and changes in deadlines.</p>
              </b-collapse>
              <div class="question expand" v-b-toggle.collapse-m ><p>Do all applications go directly to the employer?</p></div>
              <b-collapse id="collapse-m" class="answer" >
                <p>Yes, all the applications are visible only to the company. The selection process is completely managed directly by the company.</p>
              </b-collapse>
            </div>
          </div>


          <div class="row">
            <div class="col-lg-12 relatedJobs">
              <h4>Similiar jobs</h4>
              <div class="row" v-if="jobs_loading">
                <div class="col-lg-6" v-for="n in 4" :key="n">
                  <div class="card similarJob">
                    <div class="card-body jobBox">
                      <content-loader viewBox="0 0 476 124" primaryColor="#f3f3f3" secondaryColor="#cccccc" v-if="jobs_loading">
                        <rect x="0" y="8" rx="3" ry="3" width="200" height="15" />
                        <rect x="0" y="40" rx="3" ry="3" width="80" height="6" />
                        <rect x="0" y="60" rx="3" ry="3" width="100" height="6" />
                        <rect x="0" y="80" rx="3" ry="3" width="80" height="6" />
                        <rect x="300" y="100" rx="3" ry="3" width="80" height="6" />
                      </content-loader>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="jobs.length && !jobs_loading">
                <div class="col-lg-6" v-for="job in jobs.slice(0, show)" :key="job.job_id">
                  <a class="card similarJob" style="text-decoration: none;" :href="'/jobs/'+job.param">
                    <div class="card-body jobBox">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="mob-applay-btn m-show"></div>
                          <h6 class="jobTitle"><a class="text-decoration-none" :href="'/jobs/'+job.param">{{job.job_designation}}</a><!----></h6>
                          <p>
                            <span class="jobIcon"><img :src="$S3_url+'/Assets/images/company.svg'" alt="company name" title="company name" width="18"/></span>
                            <span class="jobtxt"> {{job.company_name}}</span>
                          </p>
                          <p>
                            <span class="jobIcon" v-if="job.cities"><img :src="$S3_url+'/Assets/images/location.svg'" alt="location" title="location" width="22"/></span>
                            <span class="jobtxt"><span v-for="(city, index) in job.cities" :key="index">{{city}}<span v-if="job.cities.length != index+1">,</span></span></span>
                          </p>
                          <p>
                            <span class="jobIcon" v-if="job.courses"><img :src="$S3_url+'/Assets/images/collage.svg'" alt="education" title="education" width="23"/></span>
                            <span class="jobtxt"><span v-for="(course , index) in job.courses" :key="index">{{course.name}}<span v-if="job.courses.length != index+1">,</span></span></span>
                          </p>
                          <p class="postDate">{{calculatePostDate(job.date_of_posting) }}</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" style="text-align: center">
                  <div class="loadMorebtn" @click="loadmore" v-if="this.filter.limit<10">⟳ Load more</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal id="apply-success" ref="apply-success" size="sm" title=" " body-bg-variant="primary" bod body-text-variant="light" hide-footer hide-header hide-backdrop centered>
        <div class="container text-center">Job Applied Successfully</div>
      </b-modal>
      <Modals :open="modals.open" :src="apply_job.src" :redirect_from="redirect_from"/>
    </div>
    <b-modal id="apply-success" ref="apply-success" size="sm" title=" " body-bg-variant="primary" bod body-text-variant="light" hide-footer hide-header hide-backdrop centered>
      <div class="container text-center">Job Applied Successfully</div>
    </b-modal>
    <b-modal id="city-alert" ref="city_alert" size="md" title=" "  bod hide-footer hide-header centered>
      <div class="container text-center">
        <p><strong>Are you sure you want to apply for this job?</strong></p>
        <p><strong>Your location does not match the job location</strong></p>
      </div>
      <div class="text-right">
        <a class="btn btn-secondary btn2 mr-2" v-on:click.prevent="hideModal('city_alert')">Back to jobs</a>
        <a class="btn btn-primary btn2" v-on:click="applyJob()">Proceed & Apply</a>
      </div>
    </b-modal>
    <Modals :open="modals.open" :src="apply_job.src" :redirect_from="redirect_from"/>
  </div>
</template>

  <script>
  import moment from 'moment';
  const Modals = () => import(/* webpackPrefetch: true */ "@/components/Modals");
  import Actions from '../libraries/apiActions.js';
  import validation from '../libraries/validFormat' ;
  import '../assets/hire.css';
  import { ContentLoader } from 'vue-content-loader';
  import { BCollapse } from 'bootstrap-vue'

  
  var breadcrumbJson = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": window.location.origin
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Jobs",
        "item": window.location.origin+'/jobs'
      },{
        "@type": "ListItem",
        "position": 3,
        "name": window.location.pathname.split('/')[2],
        "item": window.location.href
      }]
    }
  export default {
    name: "recruiter_dashboard",
    components: {
      Modals,
      ContentLoader, BCollapse
    },
    metaInfo: (vue) => {
      const job = vue.job;
      const job_type = vue.c_job_type;
      const qualification = vue.qualification;
      const last_date_ts = vue.last_date_ts;
      const jobLocation = vue.jobLocation;
      const seo_title = vue.seo_title;
      const meta_desc = vue.meta_desc;
      const meta_keyword = vue.meta_keyword;
      const faqs = vue.faqs;

      return {
        title: seo_title ? seo_title : '',
        meta:[
          {name:'description',content: meta_desc ? meta_desc : ''},
          {name:'keywords',content: meta_keyword ? meta_keyword : ''},
          // {property: 'og:title', content: seo_title ? seo_title : ''},
          // {property: 'og:url', content: window.location.href},
          // {property: 'og:description', content: meta_desc ? meta_desc : ''},
        ],
        link: [
          {
            rel: "canonical",
            href: process.env.VUE_APP_URL + "jobs/" + vue.$route.params.job,
          },
        ],
        script: [
          {
            type: "application/ld+json",
            json: {
              "@context": "http://schema.org/",
              "@type": "JobPosting",
              datePosted: `${job ? new Date(job.date_of_posting * 1000) : ""}`,
              validThrough: `${job ? new Date(last_date_ts * 1000) : ""}`,
              title: `${job ? job.job_designation : ""}`,
              description: `${job ? job.job_description : ""}`,
              employmentType: `${job_type ? job_type : ""}`,
              industry: "Private",
              qualifications: `${qualification ? qualification : ""}`,
              responsibilities: "-",
              hiringOrganization: {
                "@type": "Organization",
                name: `${job ? job.company_name : ""}`,
              },
              jobLocation: jobLocation,
              applicantLocationRequirements: {
                "@type": "Country",
                name: "India",
              },
              baseSalary: {
                "@type": "MonetaryAmount",
                currency: "INR",
                value: {
                  "@type": "QuantitativeValue",
                  minValue: `${job ? (job.salary_range_from ? job.salary_range_from : 0) : ""}`,
                  maxValue: `${job ? (job.salary_range_to ? job.salary_range_to : job.salary_range_from) : ""}`,
                  unitText: "monthly",
                },
              },
            },
          },
          {
            type: "application/ld+json",
            json: {
              "@context": "http://schema.org/",
              "@type": "FAQPage",
              'mainEntity': faqs.map(item => ({
              '@type': 'Question',
              'name': item.question,
              'acceptedAnswer': {
                '@type': 'Answer',
                'text': item.answer,
              },
            })),
            },
          },{
            type: "application/ld+json",
            json:breadcrumbJson
          }
        ],
      };
    },
    async beforeMount() {
      let url = this.$route.path;
      let url_split = url.split("-");
      let id = url_split[url_split.length - 1];
      let seeker = this.$cookies.get("hire-seeker");
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobile = true;
      }
      this.getJobDetails(id);
      if (seeker) {
        this.action.getUser(this.$cookies.get("hire-seeker").token).then((res) => {
            if (res) {
              this.user = res.user;
              this.getAppliedJobList();
            }
          });
        this.getUser();
      }
      await this.getCities();
      this.getQuestionnaire(id);
      // this.similiarJobs();
    },
    data() {
      return {
        modals: {
          open: "exit",
        },
        action: new Actions(),
        rec_jobs:[],
        jobs:[],
        seo_title: '',
        meta_desc: '',
        meta_keyword: '',
        filter:{
          job_role:null,
          city_id:null,
          offset: 0,
          limit:9
        },
        show: 4,
        load_more: 0,
        job_flag:0,
        total:0,
        limit:4,
        validate: new validation(),
        job: null,
        qualification: "",
        last_date_ts: null,
        jobLocation: [],
        courses: [],
        branches:[],
        cities: [],
        all_cities: [],
        jobrole: null,
        job_type: [
          { value: 1, text: "Full time" },
          { value: 2, text: "Work from home" },
          { value: 3, text: "Hybrid" },
          { value: 4, text: "Part time" },
          { value: 5, text: "Internship" },
          { value: 6, text: "Apprenticeship" },
        ],
        exp: [
          { value: 1, text: "0 Month(Fresher)" },
          { value: 2, text: "06 Months" },
          { value: 3, text: "1 Year" },
          { value: 4, text: "2 year" },
          { value: 5, text: "3 year" },
          { value: 6, text: "4 year" },
          { value: 7, text: "5 year" },
          { value: 8, text: "6 year" },
          { value: 9, text: "6+ year" },
        ],
        apply_job: {
          job_id: null,
          candidate_id: null,
          status: 0,
          applier_id: null,
          applier_type: "C",
          company_id: 1,
          src: null,
        },
        c_job_type: null,
        open: null,
        user: null,
        applied: false,
        questionnaire:[],
        hasQuestionnaire:false,
        botOptions:{
          botTitle: "Questionnaire"
        },
        isApply:false,
        botTyping: false,
        inputDisable: true,
        question_index:0,
        results: [],
        expired:false,
        loading:true,
        redirect_from: null,
        jobs_loading: true,
        gender:{
          options:[
            {value:1, text:'Male'},
            {value:2, text:'Female'},
            {value:3, text:'Both'}
          ]
        },
        error:false,
        err_msg:null,
        showDismissibleAlert:false,
        readmore:false,
        q_result:0,
        fq_course:'',
        fq_city:'',
        faqs:[],
        is_mobile:false
      };
    },
    methods: {
      calculatePostDate(date) {
        const dateObject = new Date(date * 1000);
        return moment(dateObject).fromNow();
      },
      openModal: function (type = "") {
        this.open = type == "" ? this.open : type;
        if (this.open == "success") {
          this.$refs["apply-success"].show();
        }
        this.modals.open = "exit";
        this.modals.open = type;
      },
      hideModal(name){
      this.$refs[name].hide();
      this.$router.push('/jobs');
    },
      getUser() {
        this.action.getUser(this.$cookies.get("hire-seeker").token).then((res) => {
          this.apply_job.candidate_id = res.user.id;
          this.apply_job.applier_id = res.user.id;
        });
      },
      loadmore(){
        this.load_more++;
        if(this.load_more == 1) {
          this.filter.offset += 4;
          this.show += 4;
        }else {
          let routeData = this.$router.resolve({name: 'JobList'});
          window.open(routeData.href, '_blank');
        }
      },
      getAppliedJobList() {
        this.action.getAppliedJobList(this.user.id, this.$cookies.get("hire-seeker").token).then((res) => {
          res.applied_jobs.forEach((job) => {
            if (this.job.job_id == job.job_id) {
              this.applied = true;
            }
          });
        });
      },
      getCities: async function () {
        await this.action.getCities().then(async (data) => {
          if(data.cities){
            await data.cities.forEach((element) => {
              this.all_cities.push({value: element.city_id,text: element.city_name});
            });
          }
        });
      }, 
      similiarJobs(){
        this.action.getJobList(this.filter).then(async (res) => {
          this.jobs = res.jobs; 
          this.total = res.meta.total;
          if(this.total < 8) {
          await this.action.getJobList({
              limit: 9
            }).then((res) => {
              res.jobs.forEach(job => {
                if(!this.hasDupes(this.jobs, job.job_id)) {
                  this.jobs.push(job);
                }
              });
            })
          }
          this.jobs_loading = false;
          this.jobs = this.jobs.filter(job => job.job_id !== this.job.job_id);
          this.jobs.forEach(job => {
            job.last_date = this.validate.convertTimestamp(job.last_date);
            let param = job.job_url?job.job_url:'';
            param = param.replace(/[^A-Z0-9]+/ig, "-");
            if(param.length > 80){
              let param_split = param.split("-");
              let id_length = param_split[param_split.length-1].length;
              param = param.substring(0, 80-(id_length+1));
              param = param+'-'+param_split[param_split.length-1];
            }
            job.param = param;
            // job.date_of_posting = this.validate.timeDifference(job.date_of_posting);
          })
        })
      },
      hasDupes(array, value) {
          return array.some(function (a) {
              return a.job_id == value
          });
      },
      getJobDetails(id) {
        this.action.getJobDetailsId({ id: id }).then((res) => {
          this.loading = false;
          this.job = res.data[0];
          this.seo_title = this.job.seo_title;
          this.meta_desc = this.job.seo_description;
          this.meta_keyword = this.job.seo_keywords;
          this.apply_job.job_id = this.job.job_id;
          this.last_date_ts = this.job.last_date;
          this.job.last_date = this.validate.convertTimestamp(this.job.last_date);
          if(this.last_date_ts >= new Date(new Date().setHours(0, 0, 0, 0)).getTime()/1000){
            this.expired = false;
          }else{
            this.expired = true;
          }
          this.job.courses.forEach((course, i) => {
            this.qualification += course.name;
            if (this.job.courses.length != i + 1) {
              this.qualification += ",";
            }
            this.courses.push(course.name);
            course.branches.forEach((branch) => {
              this.branches.push(course.name+'-'+branch);
              
            })
          });
          this.loading = false;
          this.filter.city_id = this.getIdsfromNames(this.job.cities, this.all_cities);
          this.filter.job_role = this.job.job_role;
          this.job.cities.forEach((city) => {
            this.jobLocation.push({
              "@type": "Place",
              address: {
                "@type": "PostalAddress",
                addressLocality: city,
                addressRegion: city,
                streetAddress: city,
                postalCode: "-",
                addressCountry: "India",
              },
            });
          });
          this.getJobrole();
          this.getJobType();
          this.similiarJobs();
          this.addFaqs();
        });
      },
      addFaqs(){
        this.faqs.push({question:'How to apply for this job?',answer:' <p> You can apply for this job simply by clicking on the ‘Apply now’ button on this page. If you are new to our website, you can register by filling in your basic details, verifying your account, and then apply for jobs.</p>'},
              {question:'Do I have to complete my profile to apply for this job?',answer:'<p> We strongly recommend completing your profile with all the necessary information. Our AI-driven system uses that information to match your applications for the jobs. Fully completed profiles will have a higher probability of shortlisting for further recruitment.</p>'},
              {question:'How to know that I am shortlisted/selected for the job?',answer:'<p>Most of the companies intimate the shortlisted /selected candidates only. Sometimes the email may land in your promotions/spam folders also. Suggest checking those folders too. If you are not hearing back from the employer for a long time, high chance that the company does not shortlist you. Continue applying for more matching <a href="/jobs" target="_blank">jobs</a></p>'},
              {question:'When can I expect to hear back from the company?',answer:'<p>Typically the hiring companies intimate the selected candidates within a week from the last date of application. This duration varies from company to company, depending on their recruitment process. </p>'},
              {question:'Do all applications go directly to the employer?',answer:'<p>Yes, all the applications are visible only to the company. The selection process is completely managed directly by the company.</p>'});
        if(this.job.salary_flag==0&&this.job.salary_range_from){
          if(this.job.salary_range_to){
            this.faqs.push({question:'What salary can I expect for this job?',answer:'<p>The offered monthly salary is INR'+this.job.salary_range_from+'<span>to INR '+this.job.salary_range_to+'</span> as per the job description. The salary offer also depends on factors like your interview performance, skills, experience, etc. </p>'})
          }else{
            this.faqs.push({question:'What salary can I expect for this job?',answer:'<p>The offered monthly salary is INR'+this.job.salary_range_from+' as per the job description. The salary offer also depends on factors like your interview performance, skills, experience, etc. </p>'})
          }
        }
        if(this.job.courses.length){
          this.job.courses.forEach((course,index) => {
            if(course.branches.length){
              course.branches.forEach(branch => {
                this.fq_course += course.name+'-'+branch;
                if(course.branches.length != index+1){
                  this.fq_course += ',';
                }
              })
            }else{
              this.fq_course += course;
            }
            if(this.job.courses.length != index+1){
                  this.fq_course += ',';
            }
          });
          this.faqs.push({question:'What is the highest qualification to apply for this job?',answer:'<p>Candidates who completed '+this.fq_course +' can apply for this job. Please check other eligibility criteria also before applying for the job.</p></p>'})
        }
        if(this.job.cities.length){
          this.job.cities.forEach((city,index)=>{
            this.fq_city += city
            if(this.job.cities.length != index + 1) {
              this.fq_city = ',';
            }
          })
          this.faqs.push({question:'Is this job vacancy available only for the mentioned location(s)?',answer:'<p>Yes, this recruitment is for '+this.fq_city+'.To find jobs near your city check out Jobs in city</p>'})
        }
        if(this.job.job_type){
          this.faqs.push({question:'What type of job is this?',answer:'<p>It is a '+this.c_job_type+'<br> For more details, please read the job description provided by the employer to know about the roles and responsibilities of this position.</p>'})
        }
        if(this.job.number_of_vacancy){
          this.faqs.push({question:'What are the skills required for this job?',answer:'<p>The candidate should have the following skills:</p>'})
        }
        if(this.job.number_of_vacancy){
          this.faqs.push({question:'What is the number of vacancies for this job?',answer:'<p>The number of vacancies available right now for this job is '+this.job.number_of_vacancy+'.</p>'})
        }
        if(this.job.hiring_process){
          this.faqs.push({question:'What is the Hiring Process for this job?',answer:'<p>The hiring for this job is through '+this.job.hiring_process+'.</p>'})
        }
        if(this.job.last_date){
          this.faqs.push({question:'What’s the last date to apply??',answer:'<p>The last date to apply for this job is '+this.job.last_date+'. Recommend to apply for the job before this date to avoid a last-minute rush and changes in deadlines.</p>'})
        }
        console.log(this.faqs);
      },
      getJobrole() {
        this.action.getJobrole().then((res) => {
          res.jobroles.forEach((role) => {
            if (role.role_id == this.job.job_role) {
              this.jobrole = role;
            }
          });
        });
      },
      getJobType() {
        this.job_type.forEach((type) => {
          if (type.value == this.job.job_type) {
            this.c_job_type = type.text;
          }
        });
      },
      getJobExp(exp1, exp2) {
        var display_exp = "";
        var experienceFrom = "";
        var experienceTo = "";

        if (exp1 == null) {
          experienceFrom = "0";
        } else if(exp1 == 0){
          experienceFrom = "0 Month";
        }else if (exp1 > 0 && exp1 < 12) {
          experienceFrom = exp1 + " Months";
        } else if (exp1 == 84) {
          experienceFrom = "6+ Years";
        } else {
          experienceFrom = exp1 / 12 + " Years";
        }

        if (exp2 == 0 || exp2 == null) {
          experienceTo = "0";
        } else if (exp2 > 0 && exp2 < 12) {
          experienceTo = exp2 + " Months";
        } else if (exp2 == 84) {
          experienceTo = "6+ Years";
        } else {
          experienceTo = exp2 / 12 + " Years";
        }


      if (experienceFrom == "0" && experienceTo == "0") {
        display_exp = "";
      } else if (exp2 == exp1) {
        display_exp = experienceTo;
      } else {
        display_exp = ((experienceFrom!=null)?experienceFrom:'') + ((experienceFrom!=null && experienceTo!="0"&&experienceTo!=null)?" to ":'') + (experienceTo!="0"&&experienceTo!=null?experienceTo:'');
      }
      return display_exp;
    },
    checkCity(){
      if (this.$cookies.get("hire-seeker")){
        if(this.user && (this.job.cities.includes(this.user.personal_details.user_city.city_name) || this.job.cities.includes('Anywhere in India'))){
          if(this.job.response_type == 1){
            this.applyJob();
          }else if(this.job.response_type == 2){
            this.newTab(this.job.link1)
          }
        }else{
          this.$refs['city_alert'].show();
        }
      }else{
        this.redirect_from = this.$route.path;
        let url = '/jobseeker/register?src='+this.apply_job.src+'&&redirect_from='+this.redirect_from;
        window.open(url, "_blank").focus();
      }
    },
    applyJob() {
      this.apply_job.src = this.$route.query.src? this.$route.query.src: "hire-job";
        if(!this.apply_job.src.includes('-jobid')){
          this.apply_job.src += '-jobid'+this.job.job_id;
        }
        this.error=false;
      if (this.job.response_type == 1) {
        this.action.applyJob(this.apply_job).then((res) => {
          if (res.status == 1) {
            this.$refs['city_alert'].hide();
              if(this.hasQuestionnaire) {
                this.isApply = true;
                this.$router.push("/questionnaire/"+this.job.job_id,)
              }else{
                this.openModal("success");
                setTimeout(() => {
                  this.$refs["apply-success"].hide();
                }, 3000);
              }
              this.applied = true;
          }
        }).catch(err => {
          console.log(err);
          this.error = true;
          if(window.navigator.onLine){
            this.err_msg = "System error! Please try again.";
          }else{
            this.err_msg = "Network error! Please check your internet connection.";
          }
        });
      } else if(this.job.response_type == 2){
        this.newTab(this.job.link1)
      }
    },
    getIdsfromNames(names, arr){
      let res = [];
      names.forEach(name => {
        arr.forEach(item => {
          if(item["text"] == name) res.push(item["value"]);
          })
      })
      return res;
    },
      newTab(url) {
        if (!url.match(/^https?:\/\//i)) {
            url = "https://" + url;
        }
        if (this.$cookies.get("hire-seeker")) {
          window.open(url, "_blank").focus();
        } else {
          this.redirect_from = url;
          let reg_url = '/jobseeker/register?src='+this.apply_job.src+'&&redirect_from='+this.redirect_from;
          window.open(reg_url, "_blank").focus();
          // this.openModal("register");
        }
      },
      getQuestionnaire(id){
        console.log(this.user);
        let uid = this.$cookies.get("hire-seeker")?this.user?.id:null;
        this.action.existQuestionnaire(id,uid).then(res => {
          if(res.status == true){
              this.hasQuestionnaire = true;
              this.q_result = res.result;
          }else{
            this.hasQuestionnaire = false;
          }
        });
      },
      async copyToClipboard(){
        await navigator.clipboard.writeText(window.location.href);
        this.showDismissibleAlert = true;
        setTimeout(() =>{
          this.showDismissibleAlert = false;
        },3000)
      },
      getGender(id){
        this.gender.options.forEach((g) => {
          if(g.value == id){
            this.user_gender = g.text;
          }
        })
        return this.user_gender;
      },
      // getExperience(){
      //   this.exp.forEach(e => {
      //     if(e.value == this.job.experience_from){
      //       this.job.experience_from = e.text
      //     }
      //     if(e.value == this.job.experience_to){
      //       this.job.experience_to = e.text
      //     }
      //   });
      // }
    }
  };
  </script>
  <style scoped> .jobIcon {margin-left: -4px;} .bottom_apply  {margin-top: 50px;margin-bottom: 50px;text-align: center;}
  </style>
